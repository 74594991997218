<template>
  <div class="flex justify-between text-sm text-cs-black dark:text-white leading-5 w-full">
    <div>
      <UserLink :id="data.senderId" :username="data.sender" />
      <span>{{ ' ' + $t(`notifications.${translation}`) }}</span>
      <slot />
    </div>
    <div class="flex ml-[10px] items-center h-full">
      <UserFollowButton v-if="!isFollowing" :isDeleted="false" :isFollowing="false" :userId="data?.senderId" class="w-max" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {NotificationItem} from '~/types/notification'
import {useGetUserIsFollowingUser} from '~/composables/user'

interface ComponentProps {
  translation: string
  data: NotificationItem
}

const props = defineProps<ComponentProps>()

const {$showErrorToast} = useNuxtApp()

const isFollowing = ref<boolean>(false)

const {error, data: followingData} = await useGetUserIsFollowingUser(props.data.senderId)
if (error && error.value) {
  $showErrorToast(error)
} else {
  isFollowing.value = followingData.value?.result || false
}
</script>
