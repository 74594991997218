<template>
  <!--
  <NuxtLink :href="feedbackLink" class="dark:text-white" target="_blank">
    <div
      class="flex items-center border border-cs-black dark:border-cs-medium-grey px-4 py-2 rounded-[50px] hover:bg-cs-light-blue hover:border-cs-light-blue hover:text-white group"
    >
      <div class="rounded-full w-[5px] h-[5px] bg-cs-red group-hover:bg-white"></div>
      <span class="block ml-[10px] mr-[5px] text-cs-black group-hover:text-white dark:text-white">{{
        $t('header.feedback')
      }}</span>
      <IconClipboard class="dark:[&>*]:stroke-white group-hover:[&>*]:stroke-white" />
    </div>
  </NuxtLink>-->
  <div></div>
</template>

<script lang="ts" setup>
const config = useRuntimeConfig()

const feedbackLink = config.public.FEEDBACK_FORM_URL
</script>
