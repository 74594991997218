<template>
  <Modal :showModal="showModal">
    <div
      class="flex flex-col bg-white dark:bg-cs-dark-background rounded-xl mx-[10px] h-full md:h-auto overflow-y-scroll max-h-[calc(100vh-40px)] md:w-[508px]"
    >
      <div class="flex flex-col rounded-t-xl justify-center items-center relative px-5 py-10">
        <IconClose
          class="cursor-pointer self-end absolute top-[10px] right-[10px] [&>g>path]:stroke-black dark:[&>g>path]:stroke-cs-medium-grey"
          @click="closeModal"
        />
        <NuxtImg
          alt="user register image"
          class="w-[292px] md:w-full h-auto"
          format="webp"
          loading="lazy"
          preload
          quality="100"
          src="/landing/people.svg"
        />
        <span class="text-cs-black dark:text-white text-[36px] font-bold leading-[43px] block my-5 text-center w-[250px]">{{
          $t('common.registerUser')
        }}</span>
        <div class="border border-cs-light-grey dark:border-cs-medium-grey rounded-[10px] w-full">
          <TabPanel :tabs="tabs" :updateTabHandler="updateSearchType" extraClass="rounded-t-[10px]">
            <template #tab-body="{selectedTab}">
              <LandingRegisterComponent
                v-if="selectedTab === 'register'"
                :isModal="true"
                :showAppButtons="false"
                :toggleModal="toggleModal"
                class="flex items-center px-5 py-[30px] border-t border-cs-light-grey dark:border-cs-medium-grey md:w-[405px] lg:w-[424px]"
                @userAuthenticated="closeModal"
              />
              <LoginComponent
                v-else
                :isModal="true"
                class="px-5 py-[30px] border-t border-cs-light-grey dark:border-cs-medium-grey"
                @loginSuccess="closeModal"
              />
            </template>
          </TabPanel>
        </div>
      </div>
      <ModalLandingRegisterConfirmation
        :email="registrationEmail"
        :showModal="canSeeConfirmationModal"
        :toggleModal="toggleConfirmationModal"
      />
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import {INTERNAL_EVENT_USER_NOT_REGISTERED} from '~/constants/internal'
import IconClose from 'assets/icons/close.svg'

const {$eventListener} = useNuxtApp()
const i18n = useI18n()

const showModal = ref<boolean>(false)
const canSeeConfirmationModal = ref<boolean>(false)
const selectedType = ref<string>('register')
const registrationEmail = ref<string>()

// If some component triggered this event then show the popup
$eventListener(INTERNAL_EVENT_USER_NOT_REGISTERED, () => {
  showModal.value = true
})

const closeModal = () => {
  showModal.value = false
}

const tabs = [
  {value: 'register', text: i18n.t('common.register')},
  {value: 'login', text: i18n.t('common.login')}
]

const updateSearchType = async (type: string) => {
  selectedType.value = type
}

const toggleModal = (value: boolean, email: string) => {
  registrationEmail.value = email
  toggleConfirmationModal()
}

const toggleConfirmationModal = () => {
  canSeeConfirmationModal.value = !canSeeConfirmationModal.value
}
</script>
