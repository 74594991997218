<template>
  <div class="switcher" @click="$toggleDarkTheme">
    <input :checked="isDarkMode" class="input" type="checkbox" />
    <span class="switch"></span>
  </div>
</template>

<script lang="ts" setup>
import {useIsDarkMode} from '~/composables/common'

interface ComponentProps {}

defineProps<ComponentProps>()

const {$toggleDarkTheme} = useNuxtApp()

const isDarkMode = useIsDarkMode()
</script>

<style scoped>
.switcher {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  width: 32px;
}

/* Visually hide the checkbox input */
.input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.switch {
  --switch-container-width: 32px;
  --switch-size: calc(var(--switch-container-width) / 2);
  --dark-gray: #312d3c;
  --gray: #cbd5e0;
  --teal: #4fd1c5;
  --dark-teal: #319795;
  /* Vertically center the inner circle */
  display: flex;
  align-items: center;
  position: relative;
  height: 20px;
  flex-basis: var(--switch-container-width);
  /* Make the container element rounded */
  border-radius: var(--switch-size);
  background-color: var(--dark-gray);
  /* In case the label gets really long, the toggle shouldn't shrink. */
  flex-shrink: 0;
  transition: background-color 0.25s ease-in-out;
}

.switch::before {
  content: '';
  position: absolute;
  /* Move a little bit the inner circle to the right */
  left: 1px;
  height: calc(var(--switch-size) - 2px);
  width: calc(var(--switch-size) - 2px);
  /* Make the inner circle fully rounded */
  border-radius: 9999px;
  background-color: white;
  border: 2px solid var(--dark-gray);
  transition: transform 0.375s ease-in-out;
}

.input:checked + .switch {
  background-color: var(--teal);
}

.input:checked + .switch::before {
  border-color: var(--teal);
  /* Move the inner circle to the right */
  transform: translateX(calc(var(--switch-container-width) - var(--switch-size)));
}

.input:focus + .switch::before {
  border-color: var(--gray);
}

.input:focus:checked + .switch::before {
  border-color: var(--dark-teal);
}

.input:disabled + .switch {
  background-color: var(--gray);
}

.input:disabled + .switch::before {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray);
}
</style>
