<template>
  <div>
    <Header />
    <!--<NuxtLoadingIndicator :throttle="0" color="#39C0C4" />-->
    <div :class="isXmas ? 'pt-[143px] lg:pt-[99px]' : 'pt-[120px] lg:pt-[99px]'">
      <!-- https://github.com/nuxt/nuxt/issues/13309 -->
      <!-- suspense wrapping should be removed in future releases but it's here because of an error -->
      <suspense>
        <slot />
      </suspense>
    </div>
    <Footer />
    <AppWidget v-if="canSeePopup()" :data="data" class="fixed bottom-5 right-5 z-10" />
    <IconScrollTop class="[&>*]:fill-cs-light-blue fixed bottom-5 right-5 cursor-pointer" @click="$scrollToTop" />
    <ModalNotRegisteredUser />
  </div>
</template>

<script lang="ts" setup>
import IconScrollTop from '~/assets/icons/scroll-top.svg'
import type {CSPotSession} from '~/types/user'
import {getCSpotSession} from '~/composables/auth/useAuth'
import {useGetIsFloatingButton, useIsXmasMode, useToggleColorMode} from '~/composables/common'
import {COLOR_MODE_DARK} from '~/plugins/darkTheme'

const {$showErrorToast, $getAbsoluteUrl, $scrollToTop} = useNuxtApp()

const route = useRoute()
const session: CSPotSession = getCSpotSession()
const isXmas = useIsXmasMode()

const {error, data} = await useGetIsFloatingButton()
if (error && error.value) {
  $showErrorToast(error)
}

const canSeePopup = () => {
  return session && session.canSeePopup && data.value && data.value.imgSrc
}

// Initialize color state
// We need to do it here because until the component it's mounted
// colorMode it's not updated with the good value
onMounted(() => {
  const colorMode = useColorMode()
  useToggleColorMode(colorMode.value === COLOR_MODE_DARK)
})

useHead(() => ({
  link: [
    {
      rel: 'canonical',
      href: $getAbsoluteUrl() + route.path
    }
  ]
}))
</script>
