<template>
  <div class="fixed top-0 w-full left-0 h-screen bg-[rgba(255,255,255,.4)] z-[100]">
    <div class="flex h-full">
      <OnClickOutside @trigger="closeModal">
        <div
          class="h-full bg-[#F1FCFB] dark:bg-cs-dark-background flex flex-col rounded-r-xl self mobileLinksDiv w-[322px] px-7 overflow-y-auto"
        >
          <div class="flex justify-between mt-[60px] mb-5">
            <CardanoSpotLogo class="block dark:hidden" />
            <CardanoSpotLogoLight class="hidden dark:block" />
            <IconClose class="self-end min-h-[32px] w-8 h-8 dark:[&>g>path]:stroke-cs-light-blue" @click="togglePanel" />
          </div>
          <slot />
        </div>
      </OnClickOutside>
    </div>
  </div>
</template>

<script lang="ts" setup>
import IconClose from '~/assets/icons/reject.svg'
import {OnClickOutside} from '@vueuse/components'

interface ComponentProps {
  togglePanel: Function
}

const props = defineProps<ComponentProps>()

const closeModal = () => {
  props.togglePanel()
}
</script>
