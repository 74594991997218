<template>
  <div
    :class="
      isXmas
        ? 'bg-[linear-gradient(86deg,#7BA196_3.36%,#1CAD8D_53.4%,#7BA196_96.64%),linear-gradient(90deg,#5AD7D7_0%,#6CBBEE_34.9%,#95B3E7_69.27%,#D8B3FA_100%)] relative h-[80px] md:h-[56px]'
        : 'bg-[linear-gradient(86deg,#76EFB5_3.36%,#1D78C7_96.64%),linear-gradient(90deg,#5AD7D7_0%,#6CBBEE_34.9%,#95B3E7_69.27%,#D8B3FA_100%)] h-[56px]'
    "
    class="w-full lg:h-auto lg:min-h-[35px] flex items-center justify-center px-5 py-1"
  >
    <span :class="isXmas ? '-mt-[25px] md:mt-0' : ''" class="text-sm text-white leading-6 w-[318px] lg:w-auto text-center"
      ><NuxtLink class="underline" target="_blank" to="https://linktr.ee/CardanoSpot">{{
        $t('common.downloadAppBanner1') + ' '
      }}</NuxtLink
      >{{ $t('common.downloadAppBanner2') }}
    </span>
    <NuxtImg
      v-if="isXmas"
      class="hidden lg:block absolute top-0 left-5"
      format="webp"
      loading="lazy"
      quality="100"
      sizes="sm:172px md:172px lg:375px"
      src="/xmas-top-banner.png"
    />
    <NuxtImg
      v-if="isXmas"
      class="hidden lg:block absolute top-0 right-5"
      format="webp"
      loading="lazy"
      quality="100"
      sizes="sm:172px md:172px lg:375px"
      src="/xmas-top-banner.png"
    />
    <NuxtImg
      v-if="isXmas"
      class="hidden md:block lg:hidden absolute bottom-0 left-5"
      format="webp"
      loading="lazy"
      quality="100"
      sizes="md:205px"
      src="/xmas-top-banner-md.png"
    />
    <NuxtImg
      v-if="isXmas"
      class="hidden md:block lg:hidden absolute bottom-0 right-5"
      format="webp"
      loading="lazy"
      quality="100"
      sizes="md:205px"
      src="/xmas-top-banner-md.png"
    />
    <NuxtImg
      v-if="isXmas"
      class="md:hidden absolute bottom-0 right-0 left-0 m-auto"
      format="webp"
      loading="lazy"
      quality="100"
      sizes="sm:318px"
      src="/xmas-top-banner-sm.png"
    />
  </div>
</template>

<script lang="ts" setup>
import {useIsXmasMode} from '~/composables/common'

const isXmas = useIsXmasMode()
</script>
