<template>
  <div>
    <MobileMenu :togglePanel="togglePanel">
      <div class="flex flex-col justify-between h-full mb-[60px]">
        <div class="flex flex-col">
          <NuxtLink
            class="flex items-center justify-start border-b border-cs-sky-blue px-3 py-3"
            to="/explore"
            @click="togglePanel"
          >
            <IconExplore class="mr-2 [&>path]:stroke-cs-black dark:[&>path]:stroke-white" />
            <span class="leading-5 text-cs-black dark:text-white">{{ $t('header.explore') }}</span>
          </NuxtLink>
          <NuxtLink
            class="flex items-center justify-start border-b border-cs-sky-blue px-3 py-3"
            to="/community-feed/all"
            @click="togglePanel"
          >
            <IconRss class="mr-2 [&>path]:stroke-cs-black dark:[&>path]:stroke-white" />
            <span class="leading-5 text-cs-black dark:text-white">{{ $t('header.community') }}</span>
          </NuxtLink>
          <NuxtLink
            class="flex items-center justify-start border-b border-cs-sky-blue px-3 py-3"
            to="/news-feed"
            @click="togglePanel"
          >
            <IconNews class="mr-2 [&>path]:stroke-cs-black dark:[&>path]:stroke-white" />
            <span class="leading-5 text-cs-black dark:text-white">{{ $t('header.news') }}</span>
          </NuxtLink>
          <NuxtLink
            class="flex items-center justify-start border-b border-cs-sky-blue px-3 py-3"
            to="/wiki/vocabulary/a"
            @click="togglePanel"
          >
            <IconBook class="mr-2 [&>g>path]:stroke-cs-black dark:[&>g>path]:stroke-white" />
            <span class="leading-5 text-cs-black dark:text-white">{{ $t('header.wiki') }}</span>
          </NuxtLink>
          <NuxtLink
            class="flex items-center justify-start border-b border-cs-sky-blue px-3 py-3"
            to="/market-status"
            @click="togglePanel"
          >
            <IconChart class="mr-2 [&>path]:stroke-cs-black dark:[&>path]:stroke-white" />
            <span class="leading-5 text-cs-black dark:text-white">{{ $t('header.marketStatus') }}</span>
          </NuxtLink>
          <NuxtLink
            class="flex items-center justify-start border-b border-cs-sky-blue px-3 py-3"
            to="/project-library/all"
            @click="togglePanel"
          >
            <IconBox class="mr-2 [&>path]:stroke-cs-black dark:[&>path]:stroke-white w-[26px] h-[26px]" />
            <span class="leading-5 text-cs-black dark:text-white">{{ $t('header.projectLibrary') }}</span>
          </NuxtLink>
          <NuxtLink
            class="flex items-center justify-start border-b border-cs-sky-blue px-3 py-3"
            to="/events"
            @click="togglePanel"
          >
            <IconCalendar class="mr-2 [&>path]:stroke-cs-black dark:[&>path]:stroke-white w-[26px] h-[26px]" />
            <span class="leading-5 text-cs-black dark:text-white">{{ $t('header.events') }}</span>
          </NuxtLink>
          <!--<NuxtLink
            :to="feedbackLink"
            class="flex items-center justify-start border-b border-cs-sky-blue px-3 py-3"
            @click="togglePanel"
          >
            <IconClipboard class="mr-2 dark:[&>*]:stroke-white group-hover:[&>*]:stroke-white" />
            <span class="leading-5 text-cs-black dark:text-white">{{ $t('header.feedback') }}</span>
          </NuxtLink>-->
        </div>
        <div v-if="user" class="flex flex-col">
          <div class="h-px w-full bg-gradient-to-r from-[#76EFB5] to-[#1D78C7] mt-10 mb-3"></div>
          <div class="flex items-center justify-between border-b border-cs-sky-blue px-3 py-5 w-full" @click="openUserPanel">
            <div class="flex justify-start items-center">
              <UserAvatar class="!w-6 !h-6 mr-2" />
              <span class="leading-5 text-cs-black dark:text-white font-bold">{{ user ? user.username : '' }}</span>
            </div>
            <IconCaretDown :class="seeUserPanel ? 'rotate-180' : ''" class="[&>*]:fill-cs-light-blue" />
          </div>
          <div v-show="seeUserPanel">
            <NuxtLink
              class="flex items-center justify-start border-cs-sky-blue px-3 py-3"
              to="/user/settings"
              @click="togglePanel"
            >
              <IconSettings class="mr-2 [&>path]:stroke-cs-light-blue" />
              <span class="leading-5 text-cs-black dark:text-white font-bold">{{ $t('header.settings') }}</span>
            </NuxtLink>
            <div class="flex items-center justify-between border-cs-sky-blue px-3 py-3">
              <div class="flex items-center">
                <IconMoon class="mr-2 [&>g>path]:stroke-cs-light-blue" />
                <span class="block mr-5 leading-5 text-cs-black dark:text-white font-bold">{{ $t('header.darkMode') }}</span>
              </div>
              <Switcher />
            </div>
            <NuxtLink
              class="flex items-center justify-start border-cs-sky-blue px-3 py-3"
              to="/user/logout"
              @click="togglePanel"
            >
              <IconLogout class="mr-2 [&>path]:stroke-cs-light-blue" />
              <span class="leading-5 text-cs-black dark:text-white font-bold">{{ $t('header.logout') }}</span>
            </NuxtLink>
          </div>
        </div>
        <div v-else>
          <div class="h-px w-full bg-gradient-to-r from-[#76EFB5] to-[#1D78C7] mt-10 mb-3"></div>
          <div class="flex items-center justify-between border-cs-sky-blue px-3 py-4">
            <div class="flex items-center">
              <IconMoon class="mr-2 [&>g>path]:stroke-cs-light-blue" />
              <span class="block mr-5 leading-5 text-cs-black dark:text-white font-bold">{{ $t('header.darkMode') }}</span>
            </div>
            <Switcher />
          </div>
          <NuxtLink class="flex items-center justify-start border-cs-sky-blue px-3 py-4" to="/user/login" @click="togglePanel">
            <IconUser class="mr-2 [&>path]:stroke-cs-light-blue" />
            <span class="leading-5 text-cs-black dark:text-white font-bold">{{ $t('header.login') }}</span>
          </NuxtLink>
        </div>
        <TechSupportButton class="mt-[10px]" @click="togglePanel" />
      </div>
    </MobileMenu>
  </div>
</template>

<script lang="ts" setup>
import IconExplore from 'assets/icons/explore.svg'
import IconNews from 'assets/icons/news.svg'
import IconBox from 'assets/icons/box.svg'
import IconRss from 'assets/icons/rss.svg'
import IconCalendar from 'assets/icons/calendar.svg'
import IconChart from 'assets/icons/chart.svg'
import IconLogout from 'assets/icons/logout.svg'
import IconSettings from 'assets/icons/settings.svg'
import IconBook from 'assets/icons/book.svg'
import UserAvatar from '~/components/features/user/UserAvatar.vue'
import Switcher from '~/components/ui/atoms/Switcher.vue'
import IconMoon from 'assets/icons/moon.svg'
import IconCaretDown from 'assets/icons/caret-down.svg'
import IconUser from 'assets/icons/landing/user.svg'

import type {CSPotSession} from '~/types/user'
import {getCSpotSession} from '~/composables/auth/useAuth'

interface ComponentProps {
  togglePanel: Function
}

defineProps<ComponentProps>()

const session: CSPotSession = getCSpotSession()

const {user} = session

const seeUserPanel = ref<boolean>(false)

const openUserPanel = () => {
  seeUserPanel.value = !seeUserPanel.value
}
</script>
