<template>
  <div class="flex justify-between text-sm text-cs-black dark:text-white leading-5">
    <div>
      <span>{{ translation }}</span>
      <slot />
    </div>
    <div class="flex ml-[10px] items-center h-full">
      <Button :onClick="goToNotification" :text="$t('notifications.watchNow')" class="w-max" type="small" variant="light" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {NotificationItem} from '~/types/notification'

interface ComponentProps {
  translation: string
  data: NotificationItem
}

const props = defineProps<ComponentProps>()

const goToNotification = () => {
  navigateTo(`/live-stream/${props.data.contentIdentifier}`)
}
</script>
