<template>
  <div
    class="cursor-pointer border dark:border-cs-medium-grey rounded-xl flex flex-col justify-start items-center bg-white dark:bg-black w-[100%] md:w-[450px] h-[350px] p-[30px]"
  >
    <div v-if="notifications.length === 0" class="flex flex-col justify-center items-center h-full w-full">
      <NuxtImg
        class="w-[250px] h-[170px] object-cover mb-5"
        format="webp"
        loading="lazy"
        preload
        quality="100"
        sizes="sm:250px md:250px lg:250px"
        src="/no-notification.png"
      />
      <span class="text-sm leading-6 text-cs-medium-grey">{{ $t('notifications.empty') }}</span>
    </div>
    <div v-else class="w-full">
      <div class="flex justify-between bg-5">
        <span class="text-sm text-cs-dark-grey leading-6">{{ $t('notifications.latest') }}</span>
        <span class="text-sm text-cs-dark-grey leading-6 cursor-pointer" @click="markAllAsRead">{{
          $t('notifications.markAll')
        }}</span>
      </div>
      <div class="overflow-y-auto h-[275px]" @scroll="handleScroll">
        <div v-for="notification in notifications">
          <Notification
            v-if="canSeeNotification(notification)"
            :data="notification"
            :hideFunction="hideFunction"
            :isSeen="isSeen"
            @deleteNotification="deleteNotification"
            @updateReadFlag="updateReadFlag"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {useReadAllNotifications} from '~/composables/notification'
import type {NotificationItem} from '~/types/notification'
import throttle from 'lodash.throttle'
import {NOTIFICATION_TYPE_ONBOARDING_SKIPPED_REMINDER} from '~/constants/notification'

interface ComponentProps {
  hideFunction: Function
  notifications: NotificationItem[]
  isSeen: boolean
}

const props = defineProps<ComponentProps>()

const {$showErrorToast} = useNuxtApp()
const emit = defineEmits(['updateReadFlag', 'deleteNotification', 'scrollEvent', 'updateReadAll'])

const markAllAsRead = async () => {
  const {error} = await useReadAllNotifications()
  if (error && error.value) {
    $showErrorToast(error)
  } else {
    emit('updateReadAll')
    props.hideFunction()
  }
}

const updateReadFlag = (id: string) => {
  emit('updateReadFlag', id)
}

const deleteNotification = (id: string) => {
  emit('deleteNotification', id)
}

const emitScrollEvent = (e: Event) => {
  emit('scrollEvent', e)
}

const handleScroll = throttle(emitScrollEvent, 100)

const canSeeNotification = (notification: NotificationItem) => {
  return notification.notificationType !== NOTIFICATION_TYPE_ONBOARDING_SKIPPED_REMINDER
}
</script>
