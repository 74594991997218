<template>
  <div>
    <div class="relative">
      <NuxtImg :src="data.imgSrc" class="cursor-pointer w-[134px] h-auto" @click="navigate" />
      <IconClose class="absolute top-0 right-0 cursor-pointer" @click="hideWidget" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import IconClose from '~/assets/icons/close-black.svg'
import {setHidePopup} from '~/composables/auth/useAuth'
import type {FloatingButtonData} from '~/types/common'
import {GTM_EVENT_SUMMIT_FLOATING_BUTTON_CLICK, GTM_EVENT_TYPE_CLICK} from '~/constants/gtmEvent'

interface ComponentProps {
  data: FloatingButtonData
}

const props = defineProps<ComponentProps>()

const {$eventTracker} = useNuxtApp()

const hideWidget = async () => {
  await setHidePopup()
}

const navigate = async () => {
  $eventTracker.triggerEvent(GTM_EVENT_SUMMIT_FLOATING_BUTTON_CLICK, GTM_EVENT_TYPE_CLICK)
  navigateTo(props.data?.actionUrl)
  await setHidePopup()
}
</script>
