import type {LoadNotificationsResponse} from '~/types/notification'
import {useFetchResource} from '~/composables/fetch/useFetchResource'

export const useGetNotifications = async (pageNumber: Ref<number>) => {
  return useFetchResource<LoadNotificationsResponse>({
    method: 'get',
    url: () => `notification?pageNumber=${pageNumber.value}`
  })
}

export const useReadAllNotifications = () => {
  return useFetchResource({
    method: 'post',
    url: 'notification'
  })
}

export const useUpdateReadNotification = (id: string) => {
  return useFetchResource({
    method: 'post',
    url: () => `notification/${id}`
  })
}
