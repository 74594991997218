<template>
  <div class="flex flex-col fixed z-20 w-full">
    <HeaderTopBanner />
    <nav
      :class="isXmas ? 'top-[80px] md:top-[56px]' : 'top-[56px]'"
      class="h-16 bg-white dark:bg-black flex justify-between items-center py-3 px-3 md:px-8 fixed z-50 w-full lg:static border-b border-[#E4F6F8] dark:border-b-[#1A1919]"
    >
      <IconMenuMobile class="lg:hidden dark:[&>path]:stroke-white" @click="toggleMobileMenu" />
      <div class="flex justify-center items-center">
        <NuxtLink href="/">
          <CardanoSpotLogo class="dark:hidden w-[172px] h-[30px]" />
          <CardanoSpotLogoLight class="hidden dark:block w-[172px] h-[30px]" />
        </NuxtLink>
      </div>
      <div class="flex linksDiv items-center">
        <MenuOption :title="$t('header.explore')" class="group">
          <NuxtLink :title="$t('header.explore')" class="hidden lg:block mx-[25px]" to="/explore">
            <IconExplore class="group-hover:[&>path]:stroke-cs-light-blue" />
          </NuxtLink>
        </MenuOption>
        <MenuOption :title="$t('header.community')" class="group">
          <NuxtLink :title="$t('header.community')" class="hidden lg:block mx-[25px]" to="/community-feed/all">
            <IconRss class="group-hover:[&>path]:stroke-cs-light-blue" />
          </NuxtLink>
        </MenuOption>
        <MenuOption :title="$t('header.news')" class="group">
          <NuxtLink :title="$t('header.news')" class="hidden lg:block mx-[25px]" to="/news-feed">
            <IconNews class="group-hover:[&>path]:stroke-cs-light-blue" />
          </NuxtLink>
        </MenuOption>
        <MenuOption :title="$t('header.wiki')" class="group">
          <NuxtLink :title="$t('header.wiki')" class="hidden lg:block mx-[25px]" to="/wiki/vocabulary/a">
            <IconBook class="group-hover:[&>path]:stroke-cs-light-blue" />
          </NuxtLink>
        </MenuOption>
        <MenuOption :title="$t('header.marketStatus')" class="group">
          <NuxtLink :title="$t('header.marketStatus')" class="hidden lg:block mx-[25px]" to="/market-status">
            <IconChart class="group-hover:[&>path]:stroke-cs-light-blue" />
          </NuxtLink>
        </MenuOption>
        <MenuOption :title="$t('header.projectLibrary')" class="group">
          <NuxtLink :title="$t('header.projectLibrary')" class="hidden lg:block mx-[25px]" to="/project-library/all">
            <IconBox class="w-[26px] h-[26px] group-hover:[&>path]:stroke-cs-light-blue" />
          </NuxtLink>
        </MenuOption>
        <MenuOption :title="$t('header.events')" class="group">
          <NuxtLink :title="$t('header.events')" class="hidden lg:block mx-[25px]" to="/events">
            <IconCalendar class="group-hover:[&>path]:stroke-cs-light-blue w-[26px] h-[26px]" />
          </NuxtLink>
        </MenuOption>
        <MenuOption :title="$t('header.search')" class="group">
          <NuxtLink :title="$t('header.search')" class="block mx-[10px] lg:mx-[25px]" to="/search">
            <IconMagnifyingGlass class="group-hover:[&>path]:stroke-cs-light-blue size-[26px]" />
          </NuxtLink>
        </MenuOption>
        <MenuOption v-if="isAdmin" class="group" title="Admin">
          <NuxtLink class="hidden lg:block mx-[25px]" title="Admin" to="/admin/community/users">
            <IconDesktop class="group-hover:[&>path]:stroke-cs-light-blue" />
          </NuxtLink>
        </MenuOption>
        <div v-if="user" class="lg:hidden flex items-center mx-[10px]">
          <NotificationsItem />
        </div>
        <LanguageSwitcher class="ml-[10px] lg:hidden" />
      </div>
      <HeaderDesktopUserPanel />
      <HeaderMobilePanel v-if="showMobileMenu" :togglePanel="toggleMobileMenu" />
    </nav>
  </div>
</template>

<script lang="ts" setup>
import IconDesktop from 'assets/icons/desktop.svg'
import IconExplore from 'assets/icons/explore.svg'
import IconMenuMobile from 'assets/icons/menu-mobile.svg'
import IconRss from 'assets/icons/rss.svg'
import IconNews from 'assets/icons/news.svg'
import IconChart from 'assets/icons/chart.svg'
import IconBox from 'assets/icons/box.svg'
import IconCalendar from 'assets/icons/calendar.svg'
import IconMagnifyingGlass from 'assets/icons/magnifying-glass.svg'
import IconBook from 'assets/icons/book.svg'
import {getCSpotSession, userIsAdmin} from '~/composables/auth/useAuth'
import MenuOption from '~/components/ui/atoms/header/MenuOption.vue'
import LanguageSwitcher from '~/components/features/other/LanguageSwitcher.vue'
import NotificationsItem from '~/components/features/header/NotificationsItem.vue'
import type {UserWithoutPassword} from '~/types/user'
import {useGetXmasMode, useIsXmasInSession, useIsXmasMode} from '~/composables/common'

const {$showErrorToast} = useNuxtApp()
const isAdmin = userIsAdmin()
const {user}: UserWithoutPassword = getCSpotSession()
const isXmas = useIsXmasMode()

const showMobileMenu = ref<boolean>(false)

const toggleMobileMenu = () => {
  showMobileMenu.value = !showMobileMenu.value
}

const {error, data} = await useGetXmasMode()
if (error && error.value) {
  $showErrorToast(error)
} else {
  useIsXmasInSession(data.value.isXmasMode)
}
</script>

<style>
html.dark nav .linksDiv path {
  stroke: white;
}

nav .linksDiv .router-link-active path,
html.dark nav .linksDiv .router-link-active path {
  stroke: #39c0c4;
}
</style>
