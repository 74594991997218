<template>
  <div class="py-[10px] flex items-center" @click="updateReadStatus">
    <div
      :class="data.isRead ? '' : 'bg-cs-light-blue'"
      class="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full mr-[10px]"
    ></div>
    <NuxtLink :to="getNotificationLink()" class="flex items-center w-full">
      <div class="w-[30px] h-[30px]">
        <NuxtImg
          :src="data && data.avatar && data.avatar !== '' ? data.avatar : '/cardano-logo-simple.png'"
          class="w-[30px] h-[30px] min-w-[30px] min-h-[30px] object-cover rounded-full"
          format="webp"
          loading="lazy"
          preload
          quality="100"
          sizes="sm:30px md:30px lg:30px"
        />
      </div>
      <div class="flex mx-[15px] w-full">
        <component :is="getComponentName()" :data="data" :translation="getComponentTranslation()">
          <span class="text-sm text-cs-medium-grey leading-5"> {{ ' ' + formatTimeAgo(data.createdOn) }}</span>
        </component>
      </div>
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import {
  NOTIFICATION_TYPE_ARTICLE_COMMENT_REPLIED,
  NOTIFICATION_TYPE_ARTICLE_LIKED,
  NOTIFICATION_TYPE_ARTICLE_NEW_COMMENT,
  NOTIFICATION_TYPE_ARTICLE_PUBLISHED,
  NOTIFICATION_TYPE_ARTICLE_REJECTED,
  NOTIFICATION_TYPE_ARTICLE_SHARED_FACEBOOK,
  NOTIFICATION_TYPE_ARTICLE_SHARED_TWITTER,
  NOTIFICATION_TYPE_ARTICLE_SUBMITTED,
  NOTIFICATION_TYPE_ARTICLE_SUBMITTED_ADMIN,
  NOTIFICATION_TYPE_COMMUNITY_PUBLISHED,
  NOTIFICATION_TYPE_COMMUNITY_REJECTED,
  NOTIFICATION_TYPE_COMMUNITY_SUBMITTED,
  NOTIFICATION_TYPE_COMMUNITY_SUBMITTED_ADMIN,
  NOTIFICATION_TYPE_EVENT_PUBLISHED,
  NOTIFICATION_TYPE_EVENT_REJECTED,
  NOTIFICATION_TYPE_EVENT_SUBMITTED,
  NOTIFICATION_TYPE_LIVESTREAM_COMING,
  NOTIFICATION_TYPE_LIVESTREAM_LIVE,
  NOTIFICATION_TYPE_LIVESTREAM_WAS_LIVE,
  NOTIFICATION_TYPE_ONBOARDING_POST,
  NOTIFICATION_TYPE_POST_COMMENT_REPLIED,
  NOTIFICATION_TYPE_POST_NEW_COMMENT,
  NOTIFICATION_TYPE_POST_SHARED_FACEBOOK,
  NOTIFICATION_TYPE_POST_SHARED_TWITTER,
  NOTIFICATION_TYPE_POST_TRENDING,
  NOTIFICATION_TYPE_POST_UPVOTED,
  NOTIFICATION_TYPE_PROJECT_PUBLISHED,
  NOTIFICATION_TYPE_PROJECT_REJECTED,
  NOTIFICATION_TYPE_PROJECT_SUBMITTED,
  NOTIFICATION_TYPE_PROJECT_SUBMITTED_ADMIN,
  NOTIFICATION_TYPE_TOKEN_PUBLISHED,
  NOTIFICATION_TYPE_TOKEN_REJECTED,
  NOTIFICATION_TYPE_TOKEN_SUBMITTED,
  NOTIFICATION_TYPE_TOKEN_SUBMITTED_ADMIN,
  NOTIFICATION_TYPE_USER_FOLLOW_YOU
} from '~/constants/notification'
import {formatDate, formatTimeAgo} from '~/util/format'
import NotificationContentUserText from '~/components/ui/molecules/notification/content/NotificationContentUserText.vue'
import NotificationContentText from '~/components/ui/molecules/notification/content/NotificationContentText.vue'
import type {NotificationItem} from '~/types/notification'
import {useUpdateReadNotification} from '~/composables/notification'
import NotificationArticleRejectedText from '~/components/ui/molecules/notification/content/NotificationArticleRejectedText.vue'
import NotificationArticleApprovedText from '~/components/ui/molecules/notification/content/NotificationArticleApprovedText.vue'
import NotificationUserFollowText from '~/components/ui/molecules/notification/content/NotificationUserFollowText.vue'
import NotificationLiveStreamText from '~/components/ui/molecules/notification/content/NotificationLiveStreamText.vue'
import NotificationEventRejectedText from '~/components/ui/molecules/notification/content/NotificationEventRejectedText.vue'
import NotificationEventApprovedText from '~/components/ui/molecules/notification/content/NotificationEventApprovedText.vue'

interface ComponentProps {
  data: NotificationItem
  hideFunction: Function
  isSeen: boolean
}

const props = defineProps<ComponentProps>()

const i18n = useI18n()
const {$showErrorToast} = useNuxtApp()

const emit = defineEmits(['updateReadFlag', 'deleteNotification'])

const getComponentName = () => {
  switch (props.data.notificationType) {
    case NOTIFICATION_TYPE_POST_NEW_COMMENT:
    case NOTIFICATION_TYPE_POST_UPVOTED:
    case NOTIFICATION_TYPE_POST_COMMENT_REPLIED:
    case NOTIFICATION_TYPE_ARTICLE_NEW_COMMENT:
    case NOTIFICATION_TYPE_ARTICLE_LIKED:
    case NOTIFICATION_TYPE_ARTICLE_COMMENT_REPLIED:
    case NOTIFICATION_TYPE_POST_SHARED_TWITTER:
    case NOTIFICATION_TYPE_POST_SHARED_FACEBOOK:
    case NOTIFICATION_TYPE_ARTICLE_SHARED_TWITTER:
    case NOTIFICATION_TYPE_ARTICLE_SHARED_FACEBOOK:
    case NOTIFICATION_TYPE_ONBOARDING_POST:
      return NotificationContentUserText
    case NOTIFICATION_TYPE_ARTICLE_PUBLISHED:
      return NotificationArticleApprovedText
    case NOTIFICATION_TYPE_EVENT_PUBLISHED:
      return NotificationEventApprovedText
    case NOTIFICATION_TYPE_LIVESTREAM_COMING:
    case NOTIFICATION_TYPE_LIVESTREAM_LIVE:
    case NOTIFICATION_TYPE_LIVESTREAM_WAS_LIVE:
      return NotificationLiveStreamText
    case NOTIFICATION_TYPE_ARTICLE_REJECTED:
      return NotificationArticleRejectedText
    case NOTIFICATION_TYPE_EVENT_REJECTED:
      return NotificationEventRejectedText
    case NOTIFICATION_TYPE_USER_FOLLOW_YOU:
      return NotificationUserFollowText
    case NOTIFICATION_TYPE_ARTICLE_SUBMITTED:
    case NOTIFICATION_TYPE_ARTICLE_SUBMITTED_ADMIN:
    case NOTIFICATION_TYPE_EVENT_SUBMITTED:
    case NOTIFICATION_TYPE_TOKEN_PUBLISHED:
    case NOTIFICATION_TYPE_TOKEN_REJECTED:
    case NOTIFICATION_TYPE_TOKEN_SUBMITTED_ADMIN:
    case NOTIFICATION_TYPE_PROJECT_PUBLISHED:
    case NOTIFICATION_TYPE_PROJECT_REJECTED:
    case NOTIFICATION_TYPE_PROJECT_SUBMITTED_ADMIN:
    case NOTIFICATION_TYPE_POST_TRENDING:
    case NOTIFICATION_TYPE_COMMUNITY_SUBMITTED_ADMIN:
    case NOTIFICATION_TYPE_COMMUNITY_SUBMITTED:
    case NOTIFICATION_TYPE_COMMUNITY_REJECTED:
    case NOTIFICATION_TYPE_COMMUNITY_PUBLISHED:
      return NotificationContentText
    default:
      return NotificationContentText
  }
}

const getComponentTranslation = () => {
  const date = new Date(props.data?.startLiveStream)
  switch (props.data.notificationType) {
    case NOTIFICATION_TYPE_POST_NEW_COMMENT:
      return 'postNewCommentText'
    case NOTIFICATION_TYPE_POST_UPVOTED:
      return 'postUpvotedText'
    case NOTIFICATION_TYPE_POST_COMMENT_REPLIED:
      return 'postCommentReplied'
    case NOTIFICATION_TYPE_ARTICLE_NEW_COMMENT:
      return 'articleNewCommentText'
    case NOTIFICATION_TYPE_ARTICLE_COMMENT_REPLIED:
      return 'articleCommentReplied'
    case NOTIFICATION_TYPE_ARTICLE_LIKED:
      return 'articleLikedText'
    case NOTIFICATION_TYPE_USER_FOLLOW_YOU:
      return 'userFollowerText'
    case NOTIFICATION_TYPE_POST_SHARED_TWITTER:
      return 'postSharedTwitterText'
    case NOTIFICATION_TYPE_POST_SHARED_FACEBOOK:
      return 'postSharedFacebookText'
    case NOTIFICATION_TYPE_ARTICLE_SHARED_TWITTER:
      return 'articleSharedTwitterText'
    case NOTIFICATION_TYPE_ARTICLE_SHARED_FACEBOOK:
      return 'articleSharedFacebookText'
    case NOTIFICATION_TYPE_ONBOARDING_POST:
      return 'onboardingPost'
    case NOTIFICATION_TYPE_ARTICLE_PUBLISHED:
      return i18n.t(`notifications.articlePublishedText`, {title: props.data.contentIdentifier})
    case NOTIFICATION_TYPE_ARTICLE_REJECTED:
      return i18n.t(`notifications.articleRejectedText`, {title: props.data.contentIdentifier})
    case NOTIFICATION_TYPE_ARTICLE_SUBMITTED:
      return i18n.t(`notifications.articleSubmittedText`)
    case NOTIFICATION_TYPE_ARTICLE_SUBMITTED_ADMIN:
      return i18n.t(`notifications.articleSubmittedAdminText`)
    case NOTIFICATION_TYPE_EVENT_PUBLISHED:
      return i18n.t(`notifications.eventPublishedText`, {title: props.data.extraText})
    case NOTIFICATION_TYPE_EVENT_REJECTED:
      return i18n.t(`notifications.eventRejectedText`, {title: props.data.extraText})
    case NOTIFICATION_TYPE_EVENT_SUBMITTED:
      return i18n.t(`notifications.eventSubmittedText`)
    case NOTIFICATION_TYPE_LIVESTREAM_COMING:
      const mins = calculateMinutesLeft(date)
      return i18n.t(`notifications.livestreamSoonText`, {date: formatDate(date), mins})
    case NOTIFICATION_TYPE_LIVESTREAM_LIVE:
      return i18n.t(`notifications.livestreamLiveText`, {date: formatDate(date)})
    case NOTIFICATION_TYPE_LIVESTREAM_WAS_LIVE:
      return i18n.t(`notifications.livestreamWasLiveText`, {date: formatDate(date)})
    case NOTIFICATION_TYPE_TOKEN_PUBLISHED:
      return i18n.t(`notifications.tokenPublishedText`, {name: props.data.extraText})
    case NOTIFICATION_TYPE_TOKEN_REJECTED:
      return i18n.t(`notifications.tokenRejectedText`, {name: props.data.extraText})
    case NOTIFICATION_TYPE_TOKEN_SUBMITTED:
      return i18n.t(`notifications.tokenSubmittedText`)
    case NOTIFICATION_TYPE_TOKEN_SUBMITTED_ADMIN:
      return i18n.t(`notifications.tokenSubmittedAdminText`)
    case NOTIFICATION_TYPE_PROJECT_PUBLISHED:
      return i18n.t(`notifications.projectPublishedText`, {name: props.data.extraText})
    case NOTIFICATION_TYPE_PROJECT_REJECTED:
      return i18n.t(`notifications.projectRejectedText`, {name: props.data.extraText})
    case NOTIFICATION_TYPE_PROJECT_SUBMITTED:
      return i18n.t(`notifications.projectSubmittedText`)
    case NOTIFICATION_TYPE_PROJECT_SUBMITTED_ADMIN:
      return i18n.t(`notifications.projectSubmittedAdminText`)
    case NOTIFICATION_TYPE_POST_TRENDING:
      return i18n.t(`notifications.postTrending`)
    case NOTIFICATION_TYPE_COMMUNITY_SUBMITTED_ADMIN:
      return i18n.t(`notifications.communitySubmittedAdmin`)
    case NOTIFICATION_TYPE_COMMUNITY_SUBMITTED:
      return i18n.t(`notifications.communitySubmitted`)
    case NOTIFICATION_TYPE_COMMUNITY_REJECTED:
      return i18n.t(`notifications.communityRejected`)
    case NOTIFICATION_TYPE_COMMUNITY_PUBLISHED:
      return i18n.t(`notifications.communityPublished`)
    default:
      return 'unknown'
  }
}

const getNotificationLink = () => {
  if (
    props.data.notificationType === NOTIFICATION_TYPE_ARTICLE_REJECTED ||
    props.data.notificationType === NOTIFICATION_TYPE_ARTICLE_SUBMITTED ||
    props.data.notificationType === NOTIFICATION_TYPE_EVENT_REJECTED ||
    props.data.notificationType === NOTIFICATION_TYPE_EVENT_SUBMITTED ||
    props.data.notificationType === NOTIFICATION_TYPE_EVENT_PUBLISHED ||
    props.data.notificationType === NOTIFICATION_TYPE_USER_FOLLOW_YOU ||
    props.data.notificationType === NOTIFICATION_TYPE_LIVESTREAM_COMING ||
    props.data.notificationType === NOTIFICATION_TYPE_LIVESTREAM_LIVE ||
    props.data.notificationType === NOTIFICATION_TYPE_LIVESTREAM_WAS_LIVE
  ) {
    // Don't create a link here as we have a button in this case
    return
  } else if (
    props.data.notificationType === NOTIFICATION_TYPE_PROJECT_PUBLISHED ||
    props.data.notificationType === NOTIFICATION_TYPE_PROJECT_REJECTED ||
    props.data.notificationType === NOTIFICATION_TYPE_PROJECT_SUBMITTED
  ) {
    return '/user/projects'
  } else if (
    props.data.notificationType === NOTIFICATION_TYPE_TOKEN_REJECTED ||
    props.data.notificationType === NOTIFICATION_TYPE_TOKEN_PUBLISHED ||
    props.data.notificationType === NOTIFICATION_TYPE_TOKEN_SUBMITTED
  ) {
    return '/user/tokens'
  } else if (
    props.data.notificationType === NOTIFICATION_TYPE_COMMUNITY_REJECTED ||
    props.data.notificationType === NOTIFICATION_TYPE_COMMUNITY_SUBMITTED
  ) {
    return '/user/communities'
  } else if (props.data.notificationType === NOTIFICATION_TYPE_COMMUNITY_PUBLISHED) {
    return `/community-feed/${props.data.contentIdentifier}`
  } else if (props.data.notificationType === NOTIFICATION_TYPE_ARTICLE_SUBMITTED_ADMIN) {
    return '/admin/news'
  } else if (props.data.notificationType === NOTIFICATION_TYPE_TOKEN_SUBMITTED_ADMIN) {
    return '/admin/token'
  } else if (props.data.notificationType === NOTIFICATION_TYPE_PROJECT_SUBMITTED_ADMIN) {
    return '/admin/project'
  } else if (props.data.notificationType === NOTIFICATION_TYPE_COMMUNITY_SUBMITTED_ADMIN) {
    return '/admin/community/community'
  }

  return `/${props.data.targetType}/${props.data.contentIdentifier}`
}

const updateReadStatus = async () => {
  if (
    props.data.notificationType === NOTIFICATION_TYPE_LIVESTREAM_COMING ||
    props.data.notificationType === NOTIFICATION_TYPE_LIVESTREAM_LIVE ||
    props.data.notificationType === NOTIFICATION_TYPE_LIVESTREAM_WAS_LIVE
  ) {
    // Don't mark as read notification in these cases
    return
  }

  const id = props.data._id
  const {error} = await useUpdateReadNotification(id)
  if (error && error.value) {
    $showErrorToast(error)
  } else {
    emit('updateReadFlag', id)
    props.hideFunction()
  }
}

const calculateMinutesLeft = (date: Date) => {
  const now = new Date()
  let differenceValue = (date.getTime() - now.getTime()) / 1000
  differenceValue /= 60
  return Math.abs(Math.round(differenceValue))
}
</script>
