<template>
  <div class="hidden lg:flex justify-center items-center">
    <FeedbackButton />
    <TechSupportButton />
    <LanguageSwitcher class="ml-[30px]" />
    <div v-if="user" class="flex items-center ml-[30px]">
      <NotificationsItem />
    </div>
    <div v-if="!user" class="flex items-center">
      <div class="px-[30px] cursor-pointer" @click="() => navigateTo('/user/login')">
        <span class="font-montserrat-normal text-cs-black dark:text-white font-medium leading-5">{{ $t('header.login') }}</span>
      </div>
      <Button :onClick="() => navigateTo('/user/register')" class="rounded-[50px]" text="Register" variant="light" />
    </div>
    <Popup v-else :icon="UserAvatar" class="ml-[30px]" customTopPosition="top-[51px]">
      <PopupItem :clickHandler="() => navigate('/user/profile')">
        <div class="flex items-center justify-start">
          <UserAvatar class="!w-6 !h-6 mr-5" />
          <span class="dark:text-white">{{ user.username }}</span>
        </div>
      </PopupItem>
      <PopupItem :clickHandler="() => navigate('/user/settings')">
        <div class="flex items-center justify-start">
          <IconSettings class="mr-5" />
          <span class="dark:text-white">{{ $t('header.settings') }}</span>
        </div>
      </PopupItem>
      <PopupItem>
        <div class="flex items-center justify-start">
          <IconMoon class="mr-5" />
          <span class="block mr-5 dark:text-white">{{ $t('header.darkMode') }}</span>
          <Switcher />
        </div>
      </PopupItem>
      <PopupItem :clickHandler="() => navigate('/user/logout')">
        <div class="flex items-center justify-start">
          <IconLogout class="mr-5" />
          <span class="dark:text-white">{{ $t('header.logout') }}</span>
        </div>
      </PopupItem>
    </Popup>
  </div>
</template>

<script lang="ts" setup>
import IconLogout from 'assets/icons/logout.svg'
import IconSettings from 'assets/icons/settings.svg'
import IconMoon from 'assets/icons/moon.svg'
import UserAvatar from '~/components/features/user/UserAvatar.vue'
import Switcher from '~/components/ui/atoms/Switcher.vue'
import type {CSPotSession} from '~/types/user'
import {getCSpotSession} from '~/composables/auth/useAuth'
import NotificationsItem from '~/components/features/header/NotificationsItem.vue'

const showUserPanel = ref<boolean>(false)

const session: CSPotSession = getCSpotSession()

const {user} = session

const toggleUserPanel = (value: boolean) => {
  showUserPanel.value = value
}

const navigate = (url: string) => {
  toggleUserPanel(false)
  navigateTo(url)
}
</script>
