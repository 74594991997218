<template>
  <div>
    <OnClickOutside @trigger="() => toggleNotificationsPanel(false)">
      <div class="relative">
        <IconBell class="cursor-pointer dark:[&>path]:stroke-white" @click="() => toggleNotificationsPanel(null)" />
        <div
          v-if="notReadNotifications"
          class="notificationsAlert absolute top-1 right-0.5 bg-red-600 h-2 w-2 rounded-full"
        ></div>
      </div>
      <div class="absolute z-50 top-[63px] md:top-[98px] min-w-[320px] right-0 lg:right-10">
        <NotificationsPanel
          v-if="isMounted"
          v-show="showNotifications"
          :hideFunction="() => toggleNotificationsPanel(false)"
          :isSeen="showNotifications"
          :notifications="allNotifications"
          @deleteNotification="deleteNotification"
          @scrollEvent="handleScroll"
          @updateReadAll="updateReadAll"
          @updateReadFlag="updateReadFlag"
        />
      </div>
    </OnClickOutside>
  </div>
</template>

<script lang="ts" setup>
import IconBell from 'assets/icons/bell.svg'
import {useGetNotifications} from '~/composables/notification'
import {OnClickOutside} from '@vueuse/components'
import type {NotificationItem} from '~/types/notification'
import {NOTIFICATION_WEBSOCKET_CHANNEL} from '~/constants/notification'

const showNotifications = ref<boolean>(false)
const allNotifications = ref<NotificationItem[]>([])
const notReadNotifications = ref<boolean>(false)
const pageNumber = ref<number>(1)
const loadPending = ref<boolean>(false)
const noMoreElements = ref<boolean>(false)
const isMounted = ref<boolean>(false)

const {$socket} = useNuxtApp()
const {$showErrorToast} = useNuxtApp()

const ROW_GAP = 300

const {data: notifications, error, refresh} = await useGetNotifications(pageNumber)

const toggleNotificationsPanel = (newState: boolean | null) => {
  if (newState === null) {
    showNotifications.value = !showNotifications.value
  } else {
    showNotifications.value = newState
  }
}

const updateMainStatus = () => {
  let flag = false
  allNotifications.value.forEach((notification, index) => {
    if (!notification.isRead) {
      flag = true
    }
  })
  notReadNotifications.value = flag
}

const loadNotifications = async () => {
  loadPending.value = true
  await refresh()
  if (error && error.value) {
    $showErrorToast(error)
  } else {
    allNotifications.value = [...allNotifications.value, ...notifications.value.data]
    updateMainStatus()

    if (!notifications?.value?.hasMoreElements) {
      noMoreElements.value = true
    }
  }
  loadPending.value = false
}

// Execute initial call
await loadNotifications()

const updateReadFlag = (id: string) => {
  allNotifications.value.forEach((notification, index) => {
    if (notification._id === id) {
      allNotifications.value[index].isRead = true
    }
  })
  updateMainStatus()
}

const updateReadAll = () => {
  allNotifications.value.forEach((notification, index) => {
    allNotifications.value[index].isRead = true
  })
  updateMainStatus()
}

const deleteNotification = (id: string) => {
  allNotifications.value.forEach((notification, index) => {
    if (notification._id === id) {
      allNotifications.value.splice(index, 1)
    }
  })
  updateMainStatus()
}

const handleScroll = async (e: Event) => {
  const {scrollTop, scrollHeight} = e.target as HTMLElement
  // If there are more elements to load and it went to bottom
  // (use this gap to don't need to go to real bottom) then load more
  if (!noMoreElements.value && scrollTop > scrollHeight - ROW_GAP) {
    await loadMore()
  }
}

const loadMore = async () => {
  pageNumber.value = pageNumber.value + 1
  await loadNotifications()
}

tryOnMounted(() => {
  // Use this flag to show notifications panel. This is used to avoid
  // an issue we have that this component was always rendered and then it was hide
  isMounted.value = true
  // Open/get websocket connection
  $socket.getInstance().on(NOTIFICATION_WEBSOCKET_CHANNEL, (data: string) => {
    const message: NotificationItem = JSON.parse(data)
    allNotifications.value = [message, ...allNotifications.value]
    updateMainStatus()
  })
})
</script>
